export const LOG_IN = "LOG_IN";
export const LOG_IN_ERROR = "LOG_IN_ERROR";
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_ERROR = "SIGN_OUT_ERROR";
export const USER_PASSWORD_RECOVERY = "USER_PASSWORD_RECOVERY";
export const UPDATED_PASS_INPUT_CHANGE = "UPDATED_PASS_INPUT_CHANGE";
export const UPDATED_USER_INPUT_CHANGE = "UPDATED_PASS_INPUT_CHANGE";
export const UPDATED_USER = "UPDATED_USER";
export const UPDATED_PASS = "UPDATED_USER";
export const UPDATED_COMPANY = "UPDATED_COMPANY";
export const GET_CONFIG = "GET_CONFIG";
export const GET_COMPANY = "GET_COMPANY";
export const GET_USER = "GET_USER";
export const PRODUCTS_START_LOADING = "PRODUCTS_START_LOADING";
export const PRODUCTS_STOP_LOADING = "PRODUCTS_STOP_LOADING";
export const NEW_PRODUCT_SCAN = "NEW_PRODUCT_SCAN";
export const NEW_PRODUCT = "NEW_PRODUCT";
export const ADD_PRODUCTS_SALE = "ADD_PRODUCTS_SALE";
export const UPDATE_PRODUCT_SALE = "UPDATE_PRODUCT_SALE";
export const FIREBASE_FAILURE = "FIREBASE_FAILURE";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const GET_STORES = "GET_STORES";
export const GET_USERS = "GET_USERS";
export const GET_SALES_TODAY_DETAILS = "GET_SALES_TODAY_DETAILS";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_SALES_BY_DATE = "GET_SALES_BY_DATE";
export const GET_SELECTED_SALE = "GET_SELECTED_SALE";
export const GET_SELECTED_USER = "GET_SELECTED_USER";
export const GET_SUMMARY_STATS = "GET_SUMMARY_STATS";
export const NEW_STOCK = "NEW_STOCK";
export const PAGINATION_STOCK = "PAGINATION_STOCK";
export const RELOAD = "RELOAD";
export const ROWS_PER_PAGE = 10;
export const INITIAL_STATE = "INITIAL_STATE";
export const CATEGORY = "CATEGORY";
export const PROVIDER = "PROVIDER";
export const PAGE = 0;



